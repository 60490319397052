import { StoryType } from "../types";
import image from "../assets/images/stories/nick_taheri.webp";
import imageMobile from "../assets/images/stories/Nick_taheri-Mobile.webp";
import image1 from "../assets/images/stories/cimone_key.webp";
import imageMobile1 from "../assets/images/stories/Cimone_key-Mobile.webp";
import image2 from "../assets/images/stories/javid_khan.webp";
import imageMobile2 from "../assets/images/stories/Javid_khan-Mobile.webp";
import image3 from "../assets/images/stories/christian_nelson.webp";
import imageMobile3 from "../assets/images/stories/Christian_nelson-Mobile.webp";
import image4 from "../assets/images/stories/adam-alami.webp";
import imageMobile4 from "../assets/images/stories/Honix_lee-Mobile.webp";
import image5 from "../assets/images/stories/sergejs_petrovs.webp";
import imageMobile5 from "../assets/images/stories/Sergejs-Petrovs-Mobile.webp";
import image6 from "../assets/images/stories/michael_crouch.webp";
import imageMobile6 from "../assets/images/stories/Michael-Crouch-Mobile.webp";
import image7 from "../assets/images/stories/Wansong_Li.webp";
import vidUrl from "../assets/video/story.mp4";
import adamAlamiVid from "../assets/video/Adam Alami.mp4";
import nicVid from "../assets/video/nickVid.mp4";

export const storiesData: Array<StoryType> = [
  {
    name: "Nick Taheri",
    company: "Creative Director, SS&C Intralinks",
    text: "Their youthful energy, professionalism, and design skills have been hallmarks of their work.",
    imageUrl: image,
    imageMobileUrl: imageMobile,
    videoUrl: nicVid,
  },
  {
    name: "Cimone Key",
    company: "CEO, CK Creative Studio",
    text: "Niwart did a fantastic job with our project. They were very professional and prepared. Their processes were in place to meet deadlines and were very friendly. The designs were well throughout, beautiful, modern, current, and up-to-date with the current trends. They took feedback well and were overall rock stars. We are hoping to work together on many other projects as well.",
    imageUrl: image1,
    imageMobileUrl: imageMobile1,
  },
  {
    name: "Javid Khan",
    company: "Director, CloudGuard",
    text: "Very talented team with the ability to construct a vision with very few upfront requirements. They absolutely nailed the brand and identity and have developed a product that I feel will disrupt the technology market. A breath of fresh air to work with, have excellent communication skills, and have been very committed to the success of the project. Thank you, team!",
    imageUrl: image2,
    imageMobileUrl: imageMobile2,
  },
  {
    name: "Christian Nelson",
    company: "CEO, Vertedge Health Analytics",
    text: "The Niwart team was highly responsive and patient with requests and feedback, much to the client's delight. The team was flexible, and internal stakeholders were particularly impressed with the vendor's innovative and results-based approach.",
    imageUrl: image3,
    imageMobileUrl: imageMobile3,
  },
  {
    name: "Adam Alami",
    company: "CEO, The Cosmic Dolphins",
    text: `"Niwart team was always able to translate our vision into a design solution."`,
    imageUrl: image4,
    imageMobileUrl: imageMobile4,
    videoUrl: adamAlamiVid,
  },
  {
    name: "Sergejs Petrovs",
    company: "CEO, SIA FOREN Technology",
    text: "Niwart's visual and functional results were met with positive acclaim from our team. The service provider ensured exceptional project management and was highly attentive to our specifications. Niwart's punctuality, cost-effectiveness, and client-oriented approach were commendable.",
    imageUrl: image5,
    imageMobileUrl: imageMobile5,
  },
  {
    name: "Michael Crouch",
    company: "Director, Kleer",
    text: "Niwart team was my only consideration when I decided to rebrand and reposition my company. From previous experience, I knew the team to be proactive, professional, and creative – everything you want and need from your design and development team. I couldn’t recommend highly enough. Always a pleasure to work together.",
    imageUrl: image6,
    imageMobileUrl: imageMobile6,
  },
  {
    name: "Wansong Li",
    company: "Managing Director, The3rd",
    text: `"...the result delivered by Niwart is more than amazing. As usual, they're the team that did perfect implementation work..."`,
    imageUrl: image7,
    imageMobileUrl: image7,
    videoUrl: vidUrl,
  },
];
